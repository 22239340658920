<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 p-2">
        <h2 class="card-title align-items-start pr-0 mr-0">
          <span class="card-label font-weight-bolder text-dark pr-0 mr-0 mx-4 my-4">
            ÖDEV KARNESİ
          </span>
        </h2>
        <div class="card-toolbar">
          <v-dialog persistent v-model="filterDialog" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small class="mx-2" v-bind="attrs" v-on="on"> FİLTRE</v-btn>
            </template>
            <v-card>
              <v-toolbar flat>
                <v-row class="justify-content-between">
                  <v-col class="col-4">
                    <v-icon @click="filterDialog = false">mdi-close</v-icon>
                  </v-col>
                  <v-col class="col-4 d-flex justify-content-center"><h5>FİLTRELE</h5></v-col>
                  <v-col class="col-4 text-right">
                    <v-icon @click="cleanFilter">mdi-refresh</v-icon>
                  </v-col>
                </v-row>
              </v-toolbar>
              <v-card-text>
                <v-autocomplete
                    v-model="lessonIds"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="lessons"
                    label="Ders"
                    single-line
                    hide-details
                    clearable
                    multiple
                    @change="lessonOnChange()"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="sourceIds"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="sources"
                    single-line
                    hide-details
                    clearable
                    multiple
                    @change="sourceOnChange()"
                    :disabled="lessonIds.length <= 0"
                    :label="lessonIds.length > 0 ? 'Kaynak' : 'Kaynak (ders seçiniz)'"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="lessonUnitIds"
                    :item-text="(item) => item.LessonUnitName"
                    :item-value="(item) => item.LessonUnitId"
                    :items="sourceTestAchievements"
                    single-line
                    hide-details
                    clearable
                    multiple
                    @change="lessonUnitOnChange()"
                    :disabled="lessonIds.length <= 0"
                    :label="lessonIds.length > 0 ? 'Ünite' : 'Ünite (ders seçiniz)'"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="subjectIds"
                    :item-text="(item) => item.ParentSubjectName"
                    :item-value="(item) => item.ParentSubjectId"
                    :items="subjects"
                    single-line
                    hide-details
                    clearable
                    multiple
                    @change="subjectOnChange()"
                    :disabled="lessonUnitIds.length <= 0"
                    :label="lessonUnitIds.length > 0 ? 'Konu' : 'Konu (ünite seçiniz)'"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="childSubjectIds"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="childSubjects"
                    single-line
                    hide-details
                    clearable
                    multiple
                    :disabled="subjectIds.length <= 0"
                    :label="subjectIds.length > 0 ? 'Alt konu' : 'Alt konu (Konu seçiniz)'"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="hwAttendanceStatusId"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="hwAttendanceStatuses"
                    label="Ödev Durumu"
                    single-line
                    hide-details
                >
                </v-autocomplete>
                <v-radio-group class="grup" v-model="tableSelector" row>
                  <v-radio label="Tümü" :color="primary" :value="0"></v-radio>
                  <v-radio
                      label="Tüm Sorularını Yaptığım Testler"
                      :color="primary"
                      :value="1"
                  ></v-radio>
                  <v-radio
                      label="Yapamadığım Soru Olan Testler"
                      :color="primary"
                      :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-card-actions class="justify-content-center">
                <v-btn @click="getData"> UYGULA</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              ref="dialog"
              v-model="dateDialog"
              :return-value.sync="dates"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="float-right mx-4 my-2" icon v-bind="attrs" v-on="on"
              >
                <v-icon large> mdi-calendar</v-icon>
              </v-btn
              >
            </template>
            <v-card>
              <v-date-picker
                  first-day-of-week="1"
                  v-model="dates"
                  scrollable
                  range
                  color="green lighten-1"
                  header-color="primary"
                  locale="tr"
                  min="2021-09-13"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="
                    getData()
                    $refs.dialog.save(dates)
                  "
                >
                  ARA
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
              <v-radio-group class="mx-3" v-model="isCheckDate" column>
                <v-radio label="Son Kontrol Tarihine Göre" :value="true"></v-radio>
                <v-radio label="Ödev Verilme Tarihine Göre" :value="false"></v-radio>
              </v-radio-group>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="card-body px-4" v-if="!loading">
        <div
            v-if="
            lessonIds.length > 0 ||
            sourceIds.length > 0 ||
            (dates[0] && dates[1]) ||
            dates[0] ||
            hwAttendanceStatusId > 0
          "
            class="container"
        >
          <h4>
            Seçili Filtreler:
            <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="lessonIds.length > 0"
            >Ders</span
            >
            <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="sourceIds.length > 0"
            >Kaynak</span
            >
            <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="lessonUnitIds.length > 0"
            >Ünite</span
            >
            <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="subjectIds.length > 0"
            >Konu</span
            >
            <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="childSubjectIds.length > 0"
            >Alt konu</span
            >
            <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="dates[0] && dates[1]"
            >Tarih: {{ dates[0] | calendar }} {{ dates[1] ? ' - ' : '' }}
              {{ dates[1] | calendar }}</span
            >
            <span class="mx-1 label label-lg label-light-primary label-inline" v-else-if="dates[0]"
            >Tarih: {{ dates[0] | calendar }} tarihinden itibaren</span
            >
            <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="hwAttendanceStatusId > 0"
            >Ödev Durumu</span
            >
          </h4>
        </div>

        <div class="row">
          <v-expansion-panels accordion multiple>
            <v-expansion-panel v-for="course in allData" :key="course.key">
              <v-expansion-panel-header color="secondary"
              >{{ course.key }}
                <template v-slot:actions>
                  <div class="d-flex flex-column">
                    <span>Detay</span>
                    <v-icon color="primary"> $expand</v-icon>
                  </div>
                </template>
                <v-chip x-small style="flex: none" class="mx-1" color="primary"
                >{{
                    course.values.reduce((accumulator, object) => {
                      return accumulator + object.QuestionCount
                    }, 0)
                  }}
                  Soru
                </v-chip
                >
                <v-chip x-small style="flex: none" color="primary"
                >{{
                    course.values.reduce((accumulator, object) => {
                      return accumulator + object.TrueAnswerCount
                    }, 0)
                  }}
                  D /
                  {{
                    course.values.reduce((accumulator, object) => {
                      return accumulator + object.WrongAnswerCount
                    }, 0)
                  }}
                  Y /
                  {{
                    course.values.reduce((accumulator, object) => {
                      return object.MarkingCount > 0 ? accumulator + object.EmptyAnswerCount : accumulator
                    }, 0)
                  }}
                  B
                </v-chip
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="table-responsive">
                  <table class="table table-bordered table-vertical-center table-sm rounded">
                    <thead class="thead-light">
                    <tr>
                      <th class="text-center col-3">ÖDEV DURUMU</th>
                      <th class="text-center col-3">KAYNAK ADI</th>
                      <th class="text-center col-3">BÖLÜM ADI</th>
                      <th class="text-center col-3">TEST ADI</th>
                      <th class="text-center col-3">SORU SAYISI</th>
                      <th class="text-center col-3">SAYFA</th>
                      <th class="text-center col-3">D</th>
                      <th class="text-center col-3">Y</th>
                      <th class="text-center col-3">B</th>
                      <th class="text-center col-3">SÇY</th>
                      <th class="text-center col-3">İŞARETLEMEDİĞİ SORULAR</th>
                      <th class="text-center col-3">YANLIŞ YAPTIĞI SORULAR</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="course.values.length <= 0" class="text-center">
                      Ödev Bulunamadı...
                    </tr>
                    <template v-else v-for="(homework, i) in course.values">
                      <tr v-bind:key="i">
                        <td class="p-2 text-center col-3">
                          {{ homework.HomeworkAttendanceStatus }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.SourceName }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.SourceChapterName }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.SourceTestName }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.QuestionCount }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.Page }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.TrueAnswerCount }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.WrongAnswerCount }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.EmptyAnswerCount }}
                        </td>
                        <td class="p-2 text-center col-3">
                          %{{ homework.CorrectPercentage }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.EmptyQuestions.replaceAll(',', ', ') }}
                        </td>
                        <td class="p-2 text-center col-3">
                          {{ homework.WrongQuestions.replaceAll(',', ', ') }}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td class="p-2 text-center col-3">
                        TEST SAYISI <br> <strong> {{ course.values.length }}</strong>
                      </td>
                      <td class="p-2 text-center col-3"></td>
                      <td class="p-2 text-center col-3"></td>
                      <td class="p-2 text-center col-3"></td>
                      <td class="p-2 text-center col-3">
                        SORU SAYISI <br> <strong> {{ totalQuestionCount(course.values) }}</strong>
                      </td>
                      <td class="p-2 text-center col-3">
                        İŞARETLENEN SORU SAYISI <br> <strong> {{ totalMarkedCount(course.values) }}</strong>
                      </td>
                      <td class="p-2 text-center col-3">
                        DOĞRU SAYISI<br> <strong>{{ totalTrueAnswerCount(course.values) }}</strong>
                      </td>
                      <td class="p-2 text-center col-3">
                        YANLIŞ SAYISI<br> <strong>{{ totalWrongAnswerCount(course.values) }}</strong>
                      </td>
                      <td class="p-2 text-center col-3">
                        BOŞ SAYISI<br> <strong>{{ totalEmptyAnswerCount(course.values) }}</strong>
                      </td>
                      <td class="p-2 text-center col-3">
                        ORTALAMA SÇY<br> <strong>%{{ correctAnswerRate(course.values) }}</strong>
                      </td>
                      <td class="p-2 text-center col-3"></td>
                      <td class="p-2 text-center col-3"></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div v-else>
        <div class="text-center mt-5">
          <div class="spinner-border"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import {CHANGE_TOKEN} from "@/core/services/store/auth.module";

export default {
  name: 'odev-karnesi',
  data() {
    return {
      allData: [],
      loading: true,
      dateDialog: false,
      dates: [null, null],
      isCheckDate: true,
      filterDialog: false,
      tableSelector: 0,
      lessons: [],
      lessonIds: [],
      sources: [],
      sourceIds: [],
      lessonUnitIds: [],
      lessonUnits: [],
      subjects: [],
      subjectIds: [],
      childSubjectIds: [],
      childSubjects: [],
      sourceTestAchievements: [],
      hwAttendanceStatusId: 0,
      hwAttendanceStatuses: [
        {Id: 0, Name: 'Tüm Ödevler'},
        {Id: 1, Name: 'Yapılanlar'},
        {Id: 2, Name: 'Yapılmayanlar'}
      ],
    }
  },
  created() {
    var userType = this.$store.getters.currentUser.User.User.UserType
    if (this.$route.query.studentId && this.$route.query.studentId > 0 && userType == 'Customer') {
      this.changeUser(this.$route.query.studentId)
    }
  },
  mounted() {
    if (this.$route.query.LessonId) {
      this.lessonIds[0] = parseInt(this.$route.query.LessonId)
      this.getLessonUnitsFromApi()
      this.lessonOnChange()
    }

    if (this.$route.query.ParentSubjectId) {
      this.subjectIds[0] = parseInt(this.$route.query.ParentSubjectId)
      this.lessonUnitOnChange()
      this.subjectOnChange()
    }
    if (this.$route.query.SubjectId) {
      this.childSubjectIds[0] = parseInt(this.$route.query.SubjectId)
    }
    if (this.$route.query.StartDate && this.$route.query.EndDate) {
      this.dates[0] = this.$route.query.StartDate
      this.dates[1] = this.$route.query.EndDate
    }
    this.getLessonsFromApi()
    this.getData()
  },
  filters: {
    calendar: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM') : ''
    }
  },
  methods: {
    totalQuestionCount(homeworks) {
      let totalQuestionCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        totalQuestionCount = totalQuestionCount += element.QuestionCount
      }
      return totalQuestionCount
    },
    totalMarkedCount(homeworks) {
      let total = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        let emptyAnswerCount = element.MarkingCount > 0 ? element.EmptyAnswerCount : 0
        if (element.MarkingCount > 0)
          total = total += (element.TrueAnswerCount + element.WrongAnswerCount + emptyAnswerCount)
      }
      return total
    },
    totalTrueAnswerCount(homeworks) {
      let totalTrueAnswerCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        totalTrueAnswerCount = totalTrueAnswerCount += element.TrueAnswerCount
      }
      return totalTrueAnswerCount
    },
    totalWrongAnswerCount(homeworks) {
      let totalWrongAnswerCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        totalWrongAnswerCount = totalWrongAnswerCount += element.WrongAnswerCount
      }
      return totalWrongAnswerCount
    },
    totalEmptyAnswerCount(homeworks) {
      let totalEmptyAnswerCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        if (element.MarkingCount > 0)
          totalEmptyAnswerCount = totalEmptyAnswerCount += element.EmptyAnswerCount
      }
      return totalEmptyAnswerCount
    },
    correctAnswerRate(homeworks) {
      let totalCorrectPercentage = 0.00
      let hwCount = 0

      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        if (element.TrueAnswerCount > 0 || element.WrongAnswerCount > 0) {
          totalCorrectPercentage = totalCorrectPercentage += element.CorrectPercentage
          hwCount++
        }
      }
      return hwCount > 0 ? (totalCorrectPercentage / hwCount).toFixed(2) : 0
    },
    getData() {
      this.loading = true
      if (this.dates[0] && this.dates[1]) {
        var g1 = new Date(this.dates[0])
        var g2 = new Date(this.dates[1])
        if (g2.getTime() < g1.getTime()) {
          var tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }
      var model = {
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        AccordingToCheckDate: this.isCheckDate,
        IsStudent: true,
        LessonIds: this.lessonIds,
        SourceIds: this.sourceIds,
        LessonUnitIds: this.lessonUnitIds,
        SubjectIds: this.subjectIds,
        ChildSubjectIds: this.childSubjectIds,
        IsCompleted: this.hwAttendanceStatusId == 1,
        IsNotCompleted: this.hwAttendanceStatusId == 2,
        IsAllQuestionCorrect: this.tableSelector == 1,
        IsAnyQuestionWrongOrEmpty: this.tableSelector == 2
      }
      ApiService.setHeader()
      ApiService.post('api/Homework/reports/studentreport', model)
          .then((data) => {
            this.allData = this.groupByArray(data.data, 'CourseName')
            this.loading = false
            this.filterDialog = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({key: v, values: [x]})
        }
        return rv
      }, [])
    },

    getLessonsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Lesson', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {IsStudent: true}
      })
          .then((data) => {
            this.lessons = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getSourcesFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Source', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {LessonIds: this.lessonIds, IsStudent: true}
      })
          .then((data) => {
            this.sources = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getLessonUnitsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/LessonUnit', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          LessonIds: this.lessonIds
        }
      })
          .then((data) => {
            this.lessonUnits = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getSourceTestAchievementsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/SourceTestAchievement', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          SourceIds: this.sourceIds,
          LessonIds: this.lessonIds
        }
      })
          .then((data) => {
            this.sourceTestAchievements = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getSubjectsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Subject', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          LessonUnitIds: this.lessonUnitIds,
          ForStudent: true
        }
      })
          .then((data) => {
            this.subjects = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    getChildSubjectsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Subject', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          ParentSubjectIds: this.subjectIds
        }
      })
          .then((data) => {
            this.childSubjects = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    cleanFilter() {
      this.lessonIds = []
      this.sourceIds = []
      this.lessonUnitIds = []
      this.subjectIds = []
      this.childSubjectIds = []
    },

    lessonOnChange() {
      this.getSourcesFromApi()
      this.getSourceTestAchievementsFromApi()
    },

    sourceOnChange() {
      this.getSourceTestAchievementsFromApi()
    },

    lessonUnitOnChange() {
      ApiService.post('api/SourceTestAchievement', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          SourceIds: this.sourceIds,
          LessonIds: this.lessonIds,
          LessonUnitIds: this.lessonUnitIds
        }
      })
          .then((data) => {
            this.subjects = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    subjectOnChange() {
      this.getChildSubjectsFromApi()
    },
    changeUser(id) {
      ApiService.setHeader()
      ApiService.post('api/customerproxyuser', {
        UserId: id,
        Type: 'Student'
      })
          .then(({data}) => {
            this.$store.dispatch(CHANGE_TOKEN, data)
            window.location.reload()
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
  }
}
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
